import react, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Loading画面
import { useLoadingModal } from '../../../components/common/LoadingModalContext';

import { getResidentAccounts, getResidentGroups } from '../../../api/datas';
import { updateResidentAccount, updateResidentGroup } from '../../../api/update';
import { deleteResidentGroup, deleteResidentAccount } from '../../../api/delete';
import { createResidentAccount, createResidentGroup } from '../../../api/create';
import { setgroups } from 'process';

interface ResidentProps {
  handleMenuClick: (menu: string) => void;
}


const ResidentManagement: React.FC<ResidentProps> = ({ handleMenuClick }) => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal(); //ローディングの表示非表示
  const [residents, setResidents] = useState<any[]>([]);
  const [selectedResident, setSelectedResident] = useState<any | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
  const [editingResident, setEditingResident] = useState<any | null>({ selectedResident });
  const [editingGroup, setEditingGroup] = useState<any | null>({ selectedGroup });
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredResidents, setFilteredResidents] = useState<any[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isResidentView, setIsResidentView] = useState<boolean>(true);
  const [isOpenMemberDialog, setIsOpenMemberDialog] = useState<boolean>(false);
  const [searchKeywordInDialog, setSearchKeywordInDialog] = useState<string>('');
  const [checkedMemberInLeftList, setCheckedMemberInLeftList] = useState<any[]>([]);
  const [checkedMemberInRightList, setCheckedMemberInRightList] = useState<any[]>([]);
  const sliderRef = useRef<Slider>(null); // Slider コンポーネントへの参照
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(1);
  const slideChangeHandler = (index: number) => {
    setCurrentSlideIndex(index);
    setSelectedResident(null);
    setSelectedGroup(null);
    sliderRef.current?.slickGoTo(index);
    if (index === 0) {
      setIsResidentView(true);
    } else {
      setIsResidentView(false);
    }
  };
  // カルーセルの設定
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    fade: true,
    appendDots: (dots: any) => {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 10, mb: 2, position: 'absolute', top: 5, left: 0, width: '100%', height: '5vh' }}>
          <Button variant="text" color="inherit" style={{ borderRadius: 0, borderBottom: currentSlideIndex == 0 ? '3px solid #8ecde2' : 'none' }} onClick={() => slideChangeHandler(0)}><Typography>{"住民管理"}</Typography></Button>
          <Button variant="text" color="inherit" style={{ borderRadius: 0, borderBottom: currentSlideIndex == 1 ? '3px solid #8ecde2' : 'none' }} onClick={() => slideChangeHandler(1)}><Typography>{"グループ管理"}</Typography></Button>
        </Box>
      )

    }
  };
  const fetchResidentAccounts = async () => {
    showLoadingModal();
    try {
      const mansionId = sessionStorage.getItem('mansionId');
      if (mansionId) {
        const residentData = await getResidentAccounts();
        setResidents(residentData);
        //部屋番号順でソート
        const sortedResidents = residentData.sort((a: any, b: any) => {
          return a.roomNumber - b.roomNumber;
        });
        setResidents(sortedResidents);
        
        setFilteredResidents(sortedResidents);
        const selectedResidentId = selectedResident?.id;
        if (selectedResidentId) {
          const foundResident = residentData?.find((resident: any) => resident.id === selectedResidentId);
          if (foundResident) {
            setEditingResident(foundResident);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching residents:', error);
    } finally {
      hideLoadingModal();
    }
  }
  const fetchResidentGroups = async () => {
    showLoadingModal();
    try {
      const residentGroups = await getResidentGroups();
      setFilteredGroups(residentGroups);
      const selectedGroupId = selectedGroup?.id;
      if (selectedGroupId) {
        const foundGroup = residentGroups?.find((group: any) => group.id === selectedGroupId);
        if (foundGroup) {
          setSelectedGroup(foundGroup);
        }
      }
      console.log(residentGroups)
    } catch (error) {
      console.error('Error fetching resident groups:', error);
    } finally {
      hideLoadingModal();
    }
  }
  useEffect(() => {
    fetchResidentAccounts();
    fetchResidentGroups();
    setCurrentSlideIndex(0);
  }, [sessionStorage.getItem('mansionId')]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
    if (isResidentView) {
      const filtered = residents.filter((resident) =>
        resident.name.includes(keyword)
      );
      setFilteredResidents(filtered);
    } else {
      const filtered = filteredGroups.filter((group) =>
        group.name.includes(keyword)
      );
      setFilteredGroups(filtered);
    }
  };
  const handleSelectList = (data: any) => {
    if (isResidentView) {
      setSelectedResident(data);
      handleEditMode(false);
    } else {
      setSelectedGroup(data);
      handleEditMode(false);
    }
  }
  const handleEditMode = (mode: boolean) => {
    if (mode) {
      setIsEditing(true);
      if (isResidentView && selectedResident) {
        setEditingResident(selectedResident);
      } else if (!isResidentView && selectedGroup) {
        setEditingGroup(selectedGroup);
      }
    } else {
      setIsEditing(false);
      setEditingResident(null);
      setEditingGroup(null);
    }
  }
  const handleCheckUpdate = (data: any, type: string) => {
    if (type === 'left') {
      const newChecked = [...checkedMemberInLeftList];
      if (newChecked.includes(data)) {
        newChecked.splice(newChecked.indexOf(data), 1);
      } else {
        newChecked.push(data);
      }
      setCheckedMemberInLeftList(newChecked);
    } else {
      const newChecked = [...checkedMemberInRightList];
      if (newChecked.includes(data)) {
        newChecked.splice(newChecked.indexOf(data), 1);
      } else {
        newChecked.push(data);
      }
      setCheckedMemberInRightList(newChecked);
    }
  }
  const handleMoveCheckedList = (type: string) => {
    if (type === 'delete') {
      //EditingGroup.membersから削除
      const newMembers = editingGroup.members.filter((member: any) => !checkedMemberInLeftList.includes(member));
      setEditingGroup({ ...editingGroup, members: newMembers });
    } else {
      //追加
      const newMembers = [...editingGroup.members, ...checkedMemberInRightList];
      setEditingGroup({ ...editingGroup, members: newMembers });
    }
  }
  const handleMoveAll = (type: string) => {
    if (type === 'delete') {
      setEditingGroup({ ...editingGroup, members: [] });
    } else {
      //追加
      setEditingGroup({ ...editingGroup, members: residents });
    }
  }
  const handleResetList = () => {
    setEditingGroup({ ...editingGroup, members: selectedGroup.members });
    setCheckedMemberInLeftList([]);
    setCheckedMemberInRightList([]);
  }
  const handleSaveClick = async () => {
    if (isResidentView) {
      await handleSaveAccountData();
    } else {
      await handleSaveAccountGroup();
    }
  }
  const handleSaveAccountData = async () => {
    try {
      showLoadingModal();
      const accountId = sessionStorage.getItem('accountId');
      const mansionId = sessionStorage.getItem('mansionId');
      if (accountId && mansionId) {
        await updateResidentAccount(
          editingResident.id,
          editingResident.name,
          editingResident.roomNumber,
          editingResident.email,
          editingResident.password
        );
      } else {
        console.error('アカウントIDまたはマンションIDが取得できませんでした。');
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      alert(`エラーが発生しました: ${error}`);
    } finally {
      hideLoadingModal();
      handleEditMode(false);
      await fetchResidentAccounts();
    }
  }
  const handleSaveAccountGroup = async () => {
    try {
      showLoadingModal();
      const accountId = sessionStorage.getItem('accountId');
      const mansionId = sessionStorage.getItem('mansionId');
      if (accountId && mansionId) {
        await updateResidentGroup(
          editingGroup.id,
          editingGroup.name,
          editingGroup.description,
          editingGroup.members
        );
      } else {
        console.error('アカウントIDまたはマンションIDが取得できませんでした。');
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      alert(`エラーが発生しました: ${error}`);
    } finally {
      hideLoadingModal();
      handleEditMode(false);
      await fetchResidentGroups();
    }
  }
  const handleDeleteClick = async () => {
    if (isResidentView) {
      await handleDeleteResidentAccount();
      setSelectedResident(null);
    } else {
      await handleDeleteAccountGroup();
      setSelectedGroup(null);
    }
  }
  const handleDeleteResidentAccount = async () => {
    try {
      showLoadingModal();
      const accountId = sessionStorage.getItem('accountId');
      const residentId = selectedResident.id;
      if (accountId && residentId) {
        await deleteResidentAccount(residentId);
      } else {
        console.error('アカウントIDまたはマンションIDが取得できませんでした。');
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      alert(`エラーが発生しました: ${error}`);
    } finally {
      handleEditMode(false);
      await fetchResidentAccounts();
      hideLoadingModal();
    }
  };
  const handleDeleteAccountGroup = async () => {
    try {
      showLoadingModal();
      const accountId = sessionStorage.getItem('accountId');
      const groupId = selectedGroup.id;
      if (accountId && groupId) {
        await deleteResidentGroup(groupId);
      } else {
        console.error('アカウントIDまたはマンションIDが取得できませんでした。');
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      alert(`エラーが発生しました: ${error}`);
    } finally {
      handleEditMode(false);
      await fetchResidentGroups();
      hideLoadingModal();
    }
  }
  const handleCreateClick = async () => {
    if (isResidentView) {
      await setSelectedResident({
        id: '',
        name: '',
        roomNumber: '',
        email: '',
        password: ''
      });
      await setEditingResident(
        {
          id: '',
          name: '',
          roomNumber: '',
          email: '',
          password: ''
        });
    } else {
      setSelectedGroup({
        id: '',
        name: '',
        description: '',
        members: []
      });
      setEditingGroup({
        id: '',
        name: '',
        description: '',
        members: []
      });
    }
    setIsEditing(true);
  }
  const handleCreateResidentAccount = async () => {
    if (editingResident.name === '') {
      alert(`名前は必須項目です。`);
      return;
    }
    try {
      showLoadingModal();
      const accountId = sessionStorage.getItem('accountId');
      const mansionId = sessionStorage.getItem('mansionId');
      if (accountId && mansionId) {
        await createResidentAccount(
          editingResident.name,
          editingResident.roomNumber,
          editingResident.email,
          editingResident.password
        );
      } else {
        console.error('アカウントIDまたはマンションIDが取得できませんでした。');
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      alert(`エラーが発生しました: ${error}`);
    } finally {
      setIsEditing(false);
      setSelectedResident(null);
      setEditingResident(null);
      await fetchResidentAccounts();
      hideLoadingModal();
    }
  }
  const handleCreateResidentGroup = async () => {
    if (editingGroup.name === '') {
      alert(`名前は必須項目です。`);
      return;
    }
    try {
      showLoadingModal();
      const accountId = sessionStorage.getItem('accountId');
      const mansionId = sessionStorage.getItem('mansionId');
      if (accountId && mansionId) {
        await createResidentGroup(
          editingGroup.name,
          editingGroup.description,
        );
      } else {
        console.error('アカウントIDまたはマンションIDが取得できませんでした。');
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      alert(`エラーが発生しました: ${error}`);
    } finally {
      setIsEditing(false);
      setSelectedGroup(null);
      setEditingGroup(null);
      await fetchResidentGroups();
      hideLoadingModal();
    }
  }

  return (
    <Box sx={{ width: '80vw', margin: 'auto', mt: 2 }}>
      <Box sx={{ height: '8vh', position: 'relative' }}>
        <ArrowBackIcon style={{ position: 'absolute', left: '20px', marginTop: '5px', cursor: 'pointer', zIndex: 999 }} onClick={() => { handleMenuClick('') }} />
        <Slider className='resident-management__slider' {...settings} ref={sliderRef} >
          <div className='resident-management__slider__content' key={"Slide-1"}>

          </div>
        </Slider>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 5, width: '100%', margin: 'auto', justifyContent: 'center' }}>
        {/**住民リスト **/}
        <Box sx={{ width: '30vw', height: '80vh' }}>
          {/**検索ボックス **/}
          <Box sx={{ width: '100%', height: '10vh' }}>
            <TextField
              value={searchKeyword}
              onChange={handleSearchChange}
              placeholder={isResidentView ? '住民名検索' : 'グループ名検索'}
              fullWidth
            />
          </Box>
          <List sx={{ height: '65vh', overflow: 'scroll' }}>
            {isResidentView ?
              filteredResidents.map((resident) => (
                <ListItemButton onClick={() => handleSelectList(resident)} sx={{ width: '100%', height: '8vh', backgroundColor: selectedResident === resident ? `lightgrey` : '' }} key={resident.id}>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={resident.name} secondary={`号室：${resident.roomNumber}`} />
                </ListItemButton>
              )) :
              filteredGroups.map((group) => (
                <ListItemButton onClick={() => handleSelectList(group)} sx={{ width: '100%', height: '8vh', backgroundColor: selectedResident === group ? `lightgrey` : '' }} key={group.id}>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={group.name} secondary={`詳細：${group.description}`} />
                </ListItemButton>
              ))
            }
          </List>
        </Box>
        {/**住民情報詳細 **/}
        <Box sx={{ width: '30vw', height: '70vh', mt: 0 }}>
          <Button variant='contained' color='primary' sx={{ display: 'flex', ml: 'auto', mb: 2, width: '30%' }}
            onClick={() => { handleCreateClick(); }}
          >
            新規追加
          </Button>
          {(selectedResident || selectedGroup) ?
            <Card sx={{ height: '100%', width: '100%', backgroundColor: '#f5f5f500', border: 'solid 1px #ccc', p: 2 }}>
              <CardContent sx={{}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" component="div">
                    {isResidentView ? '住民情報' : 'グループ情報'}
                  </Typography>
                  <DeleteIcon color='error' style={{ cursor: 'pointer' }} onClick={() => { handleDeleteClick(); }} />
                </Box>
                {isResidentView ?
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                    <TextField key={`${isEditing ? editingResident.id : selectedResident?.id}`} variant='standard' label="ID（編集不可）" value={selectedResident.id} fullWidth margin="dense" disabled />
                    <TextField variant='standard' label="名前" value={isEditing ? editingResident?.name : selectedResident.name} fullWidth margin="dense" disabled={isEditing ? false : true} onChange={(event) => { setEditingResident({ ...editingResident, name: event.target.value }) }} />
                    <TextField variant='standard' label="部屋番号" value={isEditing ? editingResident?.roomNumber : selectedResident?.roomNumber} fullWidth margin="dense" disabled={isEditing ? false : true} onChange={event => { setEditingResident({ ...editingResident, roomNumber: event.target.value }) }} />
                    <TextField variant='standard' label="連絡先（任意）" value={isEditing ? editingResident?.email : selectedResident?.email} fullWidth margin="dense" disabled={isEditing ? false : true} onChange={event => { setEditingResident({ ...editingResident, email: event.target.value }) }} />
                    <TextField variant='standard' label="パスワード（更新のみ）" value={isEditing ? editingResident?.password : selectedResident?.password} fullWidth margin="dense" disabled={isEditing ? false : true} onChange={event => { setEditingResident({ ...editingResident, password: event.target.value }) }} />
                    <TextField variant='standard' label="初回ログイン" value={new Date(selectedResident?.firstLoginAt?._seconds * 1000).toLocaleString('ja-JP')} disabled />
                    <TextField variant='standard' label="最終ログイン" value={new Date(selectedResident?.lastLoginAt?._seconds * 1000).toLocaleString('ja-JP')} disabled />
                  </Box>
                  :
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                    <TextField variant='standard' label="ID（編集不可）" value={selectedGroup?.id} fullWidth margin="normal" disabled />
                    <TextField variant='standard' label="名前" value={isEditing ? editingGroup?.name : selectedGroup?.name} fullWidth margin="normal" disabled={isEditing ? false : true} onChange={event => { setEditingGroup({ ...editingGroup, name: event.target.value }) }} />
                    <TextField variant='standard' label="詳細" value={isEditing ? editingGroup?.description : selectedGroup?.description} fullWidth margin="normal" disabled={isEditing ? false : true} onChange={event => { setEditingGroup({ ...editingGroup, description: event.target.value }) }} />
                    {
                      selectedGroup.id &&
                      <Button variant='contained' color='primary' sx={{ display: 'flex', ml: 'auto', mt: 2, width: '30%' }} disabled={isEditing ? false : true} onClick={() => { setIsOpenMemberDialog(true); }}>メンバー管理</Button>
                    }
                  </Box>
                }
              </CardContent>
              <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                {isEditing ?
                  <Box>
                    <Button color='error' size="small" onClick={() => { handleEditMode(false) }}>キャンセル</Button>
                    {(selectedGroup?.id || selectedResident?.id)
                      ?
                      <Button size="small" onClick={() => { handleSaveClick() }}>保存</Button>
                      :
                      <>
                        {
                          isResidentView ?
                            <Button size="small" onClick={() => { handleCreateResidentAccount() }}>追加</Button>
                            :
                            <Button size="small" onClick={() => { handleCreateResidentGroup() }}>追加</Button>
                        }
                      </>
                    }
                  </Box>
                  :
                  <Button size="small" onClick={() => { handleEditMode(true) }}>編集</Button>

                }
              </CardActions>
            </Card>
            :
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography variant='h6' align='center'>{isResidentView ? '編集する住民IDを選択してください。' : '編集するグループIDを選択してください。'}</Typography>
            </Box>
          }
        </Box>
      </Box>
      {editingGroup &&
        <Dialog key={editingGroup.id} open={isOpenMemberDialog} onClose={() => { setIsOpenMemberDialog(false); }} maxWidth='lg'>
          <DialogTitle alignSelf={'center'}>メンバー編集</DialogTitle>
          <DialogContent>
            <Box sx={{ width: 'fit-content', display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center', justifyContent: 'center', m: 'auto', position: 'relative' }}>
              <Typography sx={{ position: 'absolute', backgroundColor: 'white', top: -2, left: 20, zIndex: 999, }}>メンバー一覧</Typography>
              <Box sx={{ width: '25vw', height: '70vh', mt: 1 }}>
                {/**メンバーリスト **/}
                <List sx={{ height: '100%', overflow: 'scroll', border: 'solid 1px #ccc', borderRadius: 2 }}>
                  {editingGroup.members?.length > 0 && editingGroup.members?.map((member: any) => (
                    <ListItem key={member.id}>
                      <Checkbox checked={checkedMemberInLeftList.includes(member)} onClick={() => { handleCheckUpdate(member, 'left') }} />
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={member.name} secondary={`部屋番号：${member.roomNumber}`} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', gap: 3 }}>
                {/**ボタン 全員追加、チェック追加、全員削除、チェック削除 */}
                <Button variant='contained' color='primary' sx={{ width: '100%' }} onClick={() => { handleMoveAll('add') }}>全員追加</Button>
                <Button variant='contained' color='primary' sx={{ width: '100%' }} onClick={() => { handleMoveCheckedList('add') }}>チェック追加</Button>
                <Button variant='contained' color='error' sx={{ width: '100%' }} onClick={() => { handleMoveAll('delete') }}>全員削除</Button>
                <Button variant='contained' color='error' sx={{ width: '100%' }} onClick={() => { handleMoveCheckedList('delete') }}>チェック削除</Button>
              </Box>
              <Box>
                {/**参加していないメンバーリスト */}
                <Box sx={{ width: '25vw', height: '70vh', mt: 0 }}>
                  {/**検索 */}
                  <TextField value={searchKeywordInDialog}
                    onChange={(e) => setSearchKeywordInDialog(e.target.value)}
                    placeholder="メンバー検索"
                    fullWidth
                  />
                  {/**メンバーリスト **/}
                  <List sx={{ height: '62vh', overflow: 'scroll', border: 'solid 1px #ccc', borderRadius: 2, mt: 1 }}>
                    {residents.filter((resident: any) => !editingGroup.members?.some((member: any) => member.id === resident.id)).filter((resident: any) => resident.name.includes(searchKeywordInDialog)).map((resident: any) => (
                      <ListItemButton key={resident.id}>
                        <Checkbox checked={checkedMemberInRightList.includes(resident)} onClick={() => { handleCheckUpdate(resident, 'right') }} />
                        <ListItemAvatar>
                          <Avatar>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={resident.name} secondary={`部屋番号：${resident.roomNumber}`} />
                      </ListItemButton>
                    ))
                    }
                  </List>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { handleResetList(); }}>リセット</Button>
            <Button onClick={() => { setIsOpenMemberDialog(false); }}>閉じる</Button>
          </DialogActions>
        </Dialog>
      }
    </Box>
  );
};

export default ResidentManagement;
